import {Form as AntForm, InputNumber} from 'antd';

interface IInputDefaultNumber {
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    maxLength?: number;
    maxWidth?: string;
    height?: number;
    min?: number;
}

const InputDefaultNumber = (props: IInputDefaultNumber) => {
    return (
        <AntForm.Item
            name={props?.name ?? 'number'}
            label={props?.label ?? ''}
            rules={[{required: props?.required ?? false}]}
        >
            <InputNumber
                min={props?.min ?? 1}
                max={200}
                style={{
                    maxWidth: props.maxWidth ?? '100%',
                    height: props?.height,
                    borderRadius: 40,
                }}
                maxLength={props?.maxLength ?? 3}
                data-testid="input-name"
                placeholder={props?.placeholder ?? 'Nome'}
                disabled={props?.disabled ?? false}
                controls={false}
            />
        </AntForm.Item>
    );
};

export {InputDefaultNumber};
